@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500&family=Roboto:wght@400;500&display=swap');

*{padding:0px; margin:0px; border: 0px;}
*,*:before, *:after{-moz-box-sizing: border-box;-webkit-box-sizing: border-box;box-sizing: border-box;}
:focus, :active{outline: none;}
a:focus, a:active {outline: none;}
aside, nav, footer, header, section{display: block;}
html, body{height: 100%;}
body{line-height:1;font-size: 14px; -ms-text-size-adjust: 100%;-moz-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;margin:0;}
input::-ms-clear{display: none;}
button{cursor: pointer;}
button::-moz-focus-inner {padding: 0;border:0;}
a, a:visited{text-decoration: none;}
a:hover{text-decoration: none;}
ul li {list-style:none;}
img{vertical-align: top;}
h1,h2,h3,h4,h5,h6{font-weight: inherit;font-size: inherit;}
.wrapper{overflow:hidden;}

.ant-menu-item-selected{
  border-left: 3px solid #624BF5;
}

:where(.css-dev-only-do-not-override-xupi9w).ant-typography,
:where(.css-dev-only-do-not-override-xupi9w).ant-table-wrapper .ant-table-thead >tr>th,
:where(.css-dev-only-do-not-override-xupi9w).ant-table-wrapper .ant-table-thead >tr>td {
  word-break: initial;
  white-space: nowrap;
}

th, td {
  white-space: nowrap;
}

