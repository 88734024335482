.full-page-spiner {
  position: fixed;
  height: 0;
  width: 0;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.full-page-spiner:before {
  content: "";
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.05);
}